import axios from 'axios'

export class Diretor {
  static async obtemTodos (ano) {
    return axios.get('/diretor/todos/'+ano)
  }
  static async obtemUm (id) {
    return axios.get('/diretor/'+id)
  }

  static async obtemUmIdServidor (id) {
    let ano = sessionStorage.getItem("anoSelecionado");

    return axios.get('/diretor/servidor/'+id + '/ano/' + ano);
  }
  
  static async cadastrar (payload) {
    return axios.post('/diretor', payload)
  }
  static async alterar (payload) {
    return axios.put('/diretor/'+payload.id, payload)
  }
  static async remover (id) {
    return axios.delete('/diretor/'+id)
  }

  static async removerCoordenadorEscola (payload) {
    return axios.delete('/diretor/'+payload.servidor_id+'/removercoordenador/'+payload.id+'/escola')
  }

  static async buscarDiretorNaoHabilitados () {
    let ano = sessionStorage.getItem("anoSelecionado");
    return axios.get('/diretor/naoHabilitado/ano/'+ano);
  } 

  static async obtemCoordenadores (payload) {
    return axios.get('/coordenador/diretor/buscacoordenador/'+payload.servidor_id+'/'+payload.ano)
  }
}